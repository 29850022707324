<template>
  <div class="container">
    <h1>404 NotFound</h1>
    <p>请配置路由</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container {
  text-align: center;
}
</style>
